export default  {
  indexText: '随着高考改革的进行，相对单一的升学途径变得越来越多元，升学的途径已经达到20多种，为考生和家长提供了更多的选择通过本测试，你可以了解更多的升学渠道，找到最适合自己的升学之路。',
  main:{
    first:[
      {
        name: '确立正确的目标',
        text: '没有目标就没有方向，前进的过程中就会浪费大量的时间和精力，如果“罗马”不是你的目标，那努力后的价值到底是什么？就像升学，如果你不确认自己是不是喜欢、适合某个专业，或者都不了解将来从事什么职业，那升学的目的只为一个毕业证书吗？'
      },
      {
        name: '提前充分了解',
        text: '“条条大路”，每条路什么样？是平坦的高速，崎岖的山路还是带有泥洼的沼泽？ 就像升学，要提前分析每条升学路径的方式和差异，才能根据孩子个人特质去匹配适合的升学方式！'
      },
      {
        name: '提前做好准备',
        text: '确认路径后要去思考，是乘坐飞机，还是火车，是骑行还是步行？这个过程我们需要准备什么？ 就像升学，找到适合孩子的升学方式后还要清晰的知道每种方式所需，提前做足准备！'
      },
    ],
    second:[
      {
        name: '全方面信息了解、统一目的',
        text: '专家在服务过程中发现，很多家庭存在家庭意愿和孩子意愿严重冲突的问题！这些问题是可以利用科学的方式合理进行解决的，充分了解各种信息并和考生家庭达成目的统一,是升学规划很重要的一步！'
      },
      {
        name: '孩子潜在特质发掘及分析',
        text: '利用具有国家专利的测评系统，挖掘孩子个人特质（天赋、潜能、性格、兴趣、优劣势等）。人在沟通过程中，很容易陷入主观意识，会逃避或者夸大一些问题，导致有些结论不准确，利用测评工具，更好的了解孩子潜意识下的个人特质信息。'
      },
      {
        name: '提升孩子的自我认知',
        text: '通过测评报告，和家庭以及孩子再次沟通，充分分析孩子的个人特质，同时专家为孩子出具全方位分析报告，不仅让孩子对自己有了重新的认知，也让家庭对孩子有了更深刻的了解。'
      },
      {
        name: '方案确认、优化、再确认',
        text: '确认升学路径后，专家会为家庭出具细化方案，收集家庭及考生意见，再次优化方案，最终达成一致！'
      },
      {
        name: '升学路径分析、筛选',
        text: '专业定位后，专家会对升学路径进行分析并为孩子规划出适合的方式，细化每条路径所需，例：如果升学路径中有自主招生，就要继续分析上百所自招政策，为孩子匹配最适合的自招院校以及专业！'
      },
      {
        name: '筛选适合专业及职业前景分析',
        text: '通过科学的定位结果，结合孩子及家庭意愿，筛选出适合孩子的专业，并分析专业和未来职业的关系以及职业前景，让孩子有更清晰的认知，增强孩子的目标感，让持续不断的学习中充满动力！'
      }
    ]
  }
}
