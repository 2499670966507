<template>
  <div class="enter-path">
    <div class="box-container">
      <img class="index-top-image" src="@/assets/test/enter-path/enter-plan-picture1.png" />
      <div class="middle-text">{{ localJson.indexText }}</div>
      <div class="main">
        <div class="first">
          <img class="name-image" src="@/assets/test/enter-path/enter-plan-picture2.png" />
          <div class="item" v-for="(item, index) in localJson.main.first" :key="index">
            <div class="name-box">
              <div class="name">{{ item.name }}</div>
              <div class="name-right-border"></div>
            </div>
            <div class="text-box">
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="second">
          <img class="name-image" src="@/assets/test/enter-path/enter-plan-picture3.png" />
          <div class="second-box">
            <div class="item" v-for="(item, index) in localJson.main.second" :key="index">
              <div class="name-box">
                <div class="icon"></div>
                <div class="name">{{ item.name }}</div>
                <div class="name-right-border"></div>
              </div>
              <div class="text-box">
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="start-button" @click="startTest">开始测评</div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import localJson from '../local-json/enter_path'
import { getStore } from '@/utils/utils'
export default {
  name: "enter-path",
  data() {
    return {
      localJson: localJson,
    }
  },
  mounted() {

  },
  methods: {
    startTest() {
      // this.$ls.set('test-enterPath-studentID', this.$route.query);
      // this.$ls.set('OrgName', this.$route.query.OrgName);

      this.$store.dispatch('user/judgeLoginStatus').then(flag => {
        if (flag) {
          this.$router.push({
            path: '/exam-test-tips',
            query: {
              testType: 5
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '请先登录！',
            showClose: true,
            center: true
          })
          this.$router.push('/login')
        }
      })


      // let temp = getStore('LoginData')
      // if (temp) {

      //   this.$router.push({
      //     path: '/exam-test-tips',
      //     query: {
      //       testType: 5
      //     }
      //   })
      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: '请先登录！',
      //     showClose: true,
      //     center: true
      //   })
      //   this.$router.push('/login')
      // }


    }
  }
}
</script>

<style scoped lang="less">
.enter-path {
  background-color: #FAEFCA;

  .box-container {
    max-width: 750px;
    margin: auto;
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 1.5;

    .index-top-image {
      width: 100%;
    }

    .middle-text {
      padding: 20px;
      margin: 30px;
      border: 5px solid rgba(var(--themecolor),0.55);
      border-radius: 20px;
    }

    .main {
      margin: 30px;

      .name-image {
        display: block;
        width: 35%;
        margin: 30px auto;
        text-align: center;
      }

      .first {
        .item {
          padding: 40px 20px;
          margin-bottom: 50px;
          background-color: #1F3E66;
          color: #ffffff;
          position: relative;

          .name-box {
            display: flex;
            justify-content: flex-start;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);

            .name {
              height: 40px;
              line-height: 40px;
              padding: 0 20px;
              background-color: rgba(var(--themecolor),1);
            }

            .name-right-border {
              width: 0;
              height: 0;
              border: 20px solid rgba(var(--themecolor),1);
              border-left: 10px solid rgba(var(--themecolor),1);
              border-right: 10px solid transparent;
              border-bottom: 20px solid transparent;
            }
          }
        }
      }

      .second {
        .second-box {
          border-left: 5px solid #1F3E66;
          line-height: 2;

          .item {
            padding: 40px 20px;
            margin-bottom: 50px;
            position: relative;

            .name-box {
              height: 40px;
              line-height: 40px;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;

              .icon {
                width: 20px;
                height: 20px;
                border: 5px solid #1F3E66;
                border-radius: 50%;
                background-color: #FAEFCA;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-60%, -50%);
              }

              .name {
                background-color: #F4D25E;
                padding: 0 20px;
              }

              .name-right-border {
                width: 0;
                height: 0;
                border: 20px solid #F4D25E;
                border-left: 10px solid #F4D25E;
                border-bottom: 20px solid transparent;
                border-right: 10px solid transparent;
              }
            }
          }
        }
      }
    }

    .bottom-text {
      padding: 20px;
      text-align: center;
      color: #F4D25E;
    }

    .start-button {
      width: 180px;
      padding: 10px 20px;
      font-size: 18px;
      background: #fff;
      text-align: center;
      border-radius: 25px;
      box-shadow: 0 10px 15px 0 rgba(145, 133, 50, 0.6);
      margin: auto;
      cursor: pointer;
      z-index: 1;
      margin-bottom: 60px;
      border: 2px solid rgba(var(--themecolor),1);
    }
  }
}
</style>
